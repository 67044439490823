// import moment from "moment";
// import { DATE_FORMATS } from "constants/dateFormats";

// import useDateFormatter from "./formatDateWithPattern";
// import { formatTheDates } from "utils/formatTheDates";

// function useDateRange() {
//   const { formatDate } = useDateFormatter();

//   function getDateOption(startDateString: any, endDateString: any) {
//     const parseDate = (dateString: any) =>
//       moment(dateString, DATE_FORMATS.DEFAULT);

//     const startDate = parseDate(startDateString);
//     const endDate = parseDate(endDateString);
//     const today = moment();

//     const isToday =
//       startDate.isSame(today, "day") && endDate.isSame(today, "day");
//     const isYesterday =
//       startDate.isSame(today.clone().subtract(1, "day"), "day") &&
//       endDate.isSame(today.clone().subtract(1, "day"), "day");
//     const isThisWeek =
//       startDate.isSame(today.clone().startOf("week"), "day") &&
//       endDate.isSame(today.clone().endOf("week"), "day");
//     const isLastWeek =
//       startDate.isSame(
//         today.clone().subtract(1, "week").startOf("week"),
//         "day"
//       ) &&
//       endDate.isSame(today.clone().subtract(1, "week").endOf("week"), "day");
//     const isThisMonth =
//       startDate.isSame(today.clone().startOf("month"), "day") &&
//       endDate.isSame(today.clone().endOf("month"), "day");
//     const isLastMonth =
//       startDate.isSame(
//         today.clone().subtract(1, "month").startOf("month"),
//         "day"
//       ) &&
//       endDate.isSame(today.clone().subtract(1, "month").endOf("month"), "day");
//     const isLast30Days =
//       endDate.isSame(today, "day") && endDate.diff(startDate, "days") === 29;
//     const isLast90Days =
//       endDate.isSame(today, "day") && endDate.diff(startDate, "days") === 89;
//     const isLast12Months =
//       endDate.isSame(today, "day") &&
//       endDate.diff(startDate, "months", true) >= 11;
//     const isThisYear =
//       startDate.isSame(today.clone().startOf("year"), "day") &&
//       endDate.isSame(today, "day");
//     const isLastYear =
//       startDate.isSame(
//         today.clone().subtract(1, "year").startOf("year"),
//         "day"
//       ) &&
//       endDate.isSame(today.clone().subtract(1, "year").endOf("year"), "day");

//     if (isToday) return "Today";
//     if (isYesterday) return "Yesterday";
//     if (isThisWeek) return "This week";
//     if (isLastWeek) return "Last week";
//     if (isThisMonth) return "This month";
//     if (isLastMonth) return "Last month";
//     if (isLast30Days) return "Last 30 days";
//     if (isLast90Days) return "Last 90 days";
//     if (isLast12Months) return "Last 12 months";
//     if (isThisYear) return "This year";
//     if (isLastYear) return "Last year";

//     return `${formatDate(startDateString)} - ${formatDate(endDateString)}`;
//   }

//   function getFutureDateOption(startDateString: any, endDateString: any) {
//     const parseDate = (dateString: any) =>
//       moment(dateString, DATE_FORMATS.DEFAULT);

//     const startDate = parseDate(startDateString);
//     const endDate = parseDate(endDateString);
//     const today = moment();

//     const isTomorrow =
//       startDate.isSame(today.clone().add(1, "day"), "day") &&
//       endDate.isSame(today.clone().add(1, "day"), "day");
//     const isThisWeek =
//       startDate.isSame(today.clone().startOf("week"), "day") &&
//       endDate.isSame(today.clone().endOf("week"), "day");
//     const isNextWeek =
//       startDate.isSame(today.clone().add(1, "week").startOf("week"), "day") &&
//       endDate.isSame(today.clone().add(1, "week").endOf("week"), "day");
//     const isThisMonth =
//       startDate.isSame(today.clone().startOf("month"), "day") &&
//       endDate.isSame(today.clone().endOf("month"), "day");
//     const isNextMonth =
//       startDate.isSame(today.clone().add(1, "month").startOf("month"), "day") &&
//       endDate.isSame(today.clone().add(1, "month").endOf("month"), "day");
//     const isNext30Days =
//       startDate.isSameOrAfter(today.startOf("day")) &&
//       endDate.isBefore(startDate.clone().add(31, "days"));
//     const isNext90Days =
//       startDate.isSameOrAfter(today.startOf("day")) &&
//       endDate.isBefore(startDate.clone().add(91, "days"));
//     const isNext12Months =
//       startDate.isSameOrAfter(today) &&
//       endDate.isBefore(startDate.clone().add(12, "months").add(1, "day"));
//     const isThisYear =
//       startDate.isSame(today.clone().startOf("year"), "day") &&
//       endDate.isSame(today.clone().endOf("year"), "day");
//     const isNextYear =
//       startDate.isSame(today.clone().add(1, "year").startOf("year"), "day") &&
//       endDate.isSame(today.clone().add(1, "year").endOf("year"), "day");

//     if (isTomorrow) return "Tomorrow";
//     if (isThisWeek) return "This week";
//     if (isNextWeek) return "Next week";
//     if (isThisMonth) return "This month";
//     if (isNextMonth) return "Next month";
//     if (isNext30Days) return "Next 30 days";
//     if (isNext90Days) return "Next 90 days";
//     if (isNextYear) return "Next year";
//     if (isNext12Months) return "Next 12 months";
//     if (isThisYear) return "This year";

//     return `${formatDate(startDateString)} - ${formatDate(endDateString)}`;
//   }

//   function getSingleDateOption(selectedDateString: any) {
//     const parseDate = (dateString: any) =>
//       moment(dateString, DATE_FORMATS.DEFAULT);
//     const selectedDate = parseDate(selectedDateString);
//     return formatDate(selectedDate.toISOString());
//   }

//   const calculateDate = (option: any) => {
//     const today = moment();
//     const startDate = moment();
//     const endDate = moment();
//     switch (option) {
//       case "Today":
//         startDate.startOf("day");
//         break;
//       case "Yesterday":
//         startDate.subtract(1, "day").startOf("day");
//         endDate.subtract(1, "day").endOf("day");
//         break;
//       case "This week":
//         startDate.startOf("week");
//         endDate.endOf("week");
//         break;
//       case "Last week":
//         startDate.subtract(1, "week").startOf("week");
//         endDate.subtract(1, "week").endOf("week");
//         break;
//       case "This month":
//         startDate.startOf("month");
//         endDate.endOf("month");
//         break;
//       case "Last month":
//         startDate.subtract(1, "month").startOf("month");
//         endDate.subtract(1, "month").endOf("month");
//         break;
//       case "Last 30 days":
//         startDate.subtract(29, "days").startOf("day");
//         break;
//       case "Last 90 days":
//         startDate.subtract(89, "days").startOf("day");
//         break;
//       case "Last 12 months":
//         startDate.subtract(1, "year").startOf("day");
//         break;
//       case "This year":
//         startDate.startOf("year");
//         break;
//       case "Last year":
//         startDate.subtract(1, "year").startOf("year");
//         endDate.subtract(1, "year").endOf("year");
//         break;
//       default:
//         return { startDate: "", endDate: "" };
//     }

//     return {
//       startDate: startDate.isValid()
//         ? formatTheDates(startDate.toISOString())
//         : "",
//       endDate: formatTheDates(endDate.toISOString()),
//     };
//   };

//   const calculateFutureDate = (option: any) => {
//     const today = moment();
//     const startDate = moment();
//     const endDate = moment();
//     switch (option) {
//       case "Tomorrow":
//         startDate.add(1, "day").startOf("day");
//         endDate.add(1, "day").endOf("day");
//         break;
//       case "Next week":
//         startDate.add(1, "week").startOf("week");
//         endDate.add(1, "week").endOf("week");
//         break;
//       case "This week":
//         startDate.startOf("week");
//         endDate.endOf("week");
//         break;
//       case "This month":
//         startDate.startOf("month");
//         endDate.endOf("month");
//         break;
//       case "Next month":
//         startDate.add(1, "month").startOf("month");
//         endDate.add(1, "month").endOf("month");
//         break;
//       case "Next 30 days":
//         endDate.add(30, "days").endOf("day");
//         break;
//       case "Next 90 days":
//         endDate.add(90, "days").endOf("day");
//         break;
//       case "Next 12 months":
//         endDate.add(12, "months").endOf("day");
//         break;
//       case "This year":
//         startDate.startOf("year");
//         endDate.endOf("year");
//         break;
//       case "Next year":
//         startDate.add(1, "year").startOf("year");
//         endDate.add(1, "year").endOf("year");
//         break;
//       default:
//         return { startDate: "", endDate: "" };
//     }

//     return {
//       startDate: startDate.isValid()
//         ? formatTheDates(startDate.toISOString())
//         : "",
//       endDate: formatTheDates(endDate.toISOString()),
//     };
//   };

//   return {
//     getDateOption,
//     getSingleDateOption,
//     calculateDate,
//     calculateFutureDate,
//     getFutureDateOption,
//   };
// }

// export default useDateRange;

import moment from "moment";
import useDateFormatter from "./formatDateWithPattern";

function useDateRange() {
  const { formatDate } = useDateFormatter();

  function getDateOption(startDateString: any, endDateString: any) {
    const parseDate = (dateString: any) => moment(dateString, "YYYY-MM-DD");

    const startDate = parseDate(startDateString);
    const endDate = parseDate(endDateString);
    const today = moment();

    const isToday =
      startDate.isSame(today, "day") && endDate.isSame(today, "day");
    const isYesterday =
      startDate.isSame(today.clone().subtract(1, "day"), "day") &&
      endDate.isSame(today.clone().subtract(1, "day"), "day");
    const isThisWeek =
      startDate.isSame(today.clone().startOf("week"), "day") &&
      endDate.isSame(today.clone().endOf("week"), "day");
    const isLastWeek =
      startDate.isSame(
        today.clone().subtract(1, "week").startOf("week"),
        "day"
      ) &&
      endDate.isSame(today.clone().subtract(1, "week").endOf("week"), "day");
    const isThisMonth =
      startDate.isSame(today.clone().startOf("month"), "day") &&
      endDate.isSame(today.clone().endOf("month"), "day");
    const isLastMonth =
      startDate.isSame(
        today.clone().subtract(1, "month").startOf("month"),
        "day"
      ) &&
      endDate.isSame(today.clone().subtract(1, "month").endOf("month"), "day");
    const isLast30Days =
      endDate.isSame(today, "day") && endDate.diff(startDate, "days") === 29;
    const isLast90Days =
      endDate.isSame(today, "day") && endDate.diff(startDate, "days") === 89;
    const isLast12Months =
      endDate.isSame(today, "day") &&
      endDate.diff(startDate, "months", true) >= 11;
    const isThisYear =
      startDate.isSame(today.clone().startOf("year"), "day") &&
      endDate.isSame(today, "day");
    const isLastYear =
      startDate.isSame(
        today.clone().subtract(1, "year").startOf("year"),
        "day"
      ) &&
      endDate.isSame(today.clone().subtract(1, "year").endOf("year"), "day");

    if (isToday) return "Today";
    if (isYesterday) return "Yesterday";
    if (isThisWeek) return "This week";
    if (isLastWeek) return "Last week";
    if (isThisMonth) return "This month";
    if (isLastMonth) return "Last month";
    if (isLast30Days) return "Last 30 days";
    if (isLast90Days) return "Last 90 days";
    if (isLast12Months) return "Last 12 months";
    if (isThisYear) return "This year";
    if (isLastYear) return "Last year";

    return `${formatDate(startDateString)} - ${formatDate(endDateString)}`;
  }

  function getFutureDateOption(startDateString: any, endDateString: any) {
    const parseDate = (dateString: any) => moment(dateString, "YYYY-MM-DD");

    const startDate = parseDate(startDateString);
    const endDate = parseDate(endDateString);
    const today = moment();

    const isTomorrow =
      startDate.isSame(today.clone().add(1, "day"), "day") &&
      endDate.isSame(today.clone().add(1, "day"), "day");
    const isThisWeek =
      startDate.isSame(today.clone().startOf("week"), "day") &&
      endDate.isSame(today.clone().endOf("week"), "day");
    const isNextWeek =
      startDate.isSame(today.clone().add(1, "week").startOf("week"), "day") &&
      endDate.isSame(today.clone().add(1, "week").endOf("week"), "day");
    const isThisMonth =
      startDate.isSame(today.clone().startOf("month"), "day") &&
      endDate.isSame(today.clone().endOf("month"), "day");
    const isNextMonth =
      startDate.isSame(today.clone().add(1, "month").startOf("month"), "day") &&
      endDate.isSame(today.clone().add(1, "month").endOf("month"), "day");
    const isNext30Days =
      startDate.isSameOrAfter(today.startOf("day")) &&
      endDate.isBefore(startDate.clone().add(31, "days"));
    const isNext90Days =
      startDate.isSameOrAfter(today.startOf("day")) &&
      endDate.isBefore(startDate.clone().add(91, "days"));
    const isNext12Months =
      startDate.isSameOrAfter(today) &&
      endDate.isBefore(startDate.clone().add(12, "months").add(1, "day"));
    const isThisYear =
      startDate.isSame(today.clone().startOf("year"), "day") &&
      endDate.isSame(today.clone().endOf("year"), "day");
    const isNextYear =
      startDate.isSame(today.clone().add(1, "year").startOf("year"), "day") &&
      endDate.isSame(today.clone().add(1, "year").endOf("year"), "day");

    if (isTomorrow) return "Tomorrow";
    if (isThisWeek) return "This week";
    if (isNextWeek) return "Next week";
    if (isThisMonth) return "This month";
    if (isNextMonth) return "Next month";
    if (isNext30Days) return "Next 30 days";
    if (isNext90Days) return "Next 90 days";
    if (isNextYear) return "Next year";
    if (isNext12Months) return "Next 12 months";
    if (isThisYear) return "This year";

    return `${formatDate(startDateString)} - ${formatDate(endDateString)}`;
  }

  function getSingleDateOption(selectedDateString: any) {
    const parseDate = (dateString: any) => moment(dateString, "YYYY-MM-DD");
    const selectedDate = parseDate(selectedDateString);
    return formatDate(selectedDate.format("YYYY-MM-DD"));
  }

  const calculateDate = (option: any) => {
    const today = moment();
    const startDate = moment();
    const endDate = moment();
    switch (option) {
      case "Today":
        startDate.startOf("day");
        break;
      case "Yesterday":
        startDate.subtract(1, "day").startOf("day");
        endDate.subtract(1, "day").endOf("day");
        break;
      case "This week":
        startDate.startOf("week");
        endDate.endOf("week"); // Update endDate for "This week"
        break;
      case "Last week":
        startDate.subtract(1, "week").startOf("week");
        endDate.subtract(1, "week").endOf("week");
        break;
      case "This month":
        startDate.startOf("month");
        endDate.endOf("month");
        break;
      case "Last month":
        startDate.subtract(1, "month").startOf("month");
        endDate.subtract(1, "month").endOf("month");
        break;
      case "Last 30 days":
        startDate.subtract(29, "days").startOf("day");
        break;
      case "Last 90 days":
        startDate.subtract(89, "days").startOf("day");
        break;
      case "Last 12 months":
        startDate.subtract(1, "year").startOf("day");
        break;
      case "This year":
        startDate.startOf("year");
        break;
      case "Last year":
        startDate.subtract(1, "year").startOf("year");
        endDate.subtract(1, "year").endOf("year");
        break;
      default:
        return { startDate: "", endDate: "" };
    }

    return {
      startDate: startDate.isValid() ? startDate.format("YYYY-MM-DD") : "",
      endDate: endDate.format("YYYY-MM-DD"),
    };
  };
  const calculateFutureDate = (option: any) => {
    const today = moment();
    const startDate = moment();
    const endDate = moment();
    switch (option) {
      case "Tomorrow":
        startDate.add(1, "day").startOf("day");
        endDate.add(1, "day").endOf("day");
        break;
      case "Next week":
        startDate.add(1, "week").startOf("week");
        endDate.add(1, "week").endOf("week");
        break;
      case "This week":
        startDate.startOf("week");
        endDate.endOf("week");
        break;
      case "This month":
        startDate.startOf("month");
        endDate.endOf("month");
        break;
      case "Next month":
        startDate.add(1, "month").startOf("month");
        endDate.add(1, "month").endOf("month");
        break;
      case "Next 30 days":
        endDate.add(30, "days").endOf("day");
        break;
      case "Next 90 days":
        endDate.add(90, "days").endOf("day");
        break;
      case "Next 12 months":
        endDate.add(12, "months").endOf("day");
        break;
      case "This year":
        startDate.startOf("year");
        endDate.endOf("year");
        break;
      case "Next year":
        startDate.add(1, "year").startOf("year");
        endDate.add(1, "year").endOf("year");
        break;
      default:
        return { startDate: "", endDate: "" };
    }

    return {
      startDate: startDate.isValid() ? startDate.format("YYYY-MM-DD") : "",
      endDate: endDate.format("YYYY-MM-DD"),
    };
  };

  return {
    getDateOption,
    getSingleDateOption,
    calculateDate,
    calculateFutureDate,
    getFutureDateOption,
  };
}

export default useDateRange;
