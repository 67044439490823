import React, { useState, useEffect } from "react";
import ReactApexCharts from "react-apexcharts";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

export interface BarGraphProps {
  graphData: any;
  isDateFilter: boolean;
}
export interface GraphDataItem {
  date: string;
  downloads: number;
  views: number;
}

export const BarGraph: React.FC<BarGraphProps> = ({
  graphData,
  isDateFilter,
}) => {
  // const [displayedData, setDisplayedData] = useState<GraphDataItem[]>([]);

  // //To Show Last 30 Days Data By Default
  // useEffect(() => {
  //   const dataToDisplay = isDateFilter ? graphData : graphData?.slice(-30);
  //   setDisplayedData(dataToDisplay);
  // }, [graphData, isDateFilter, setDisplayedData]);

  const { dates, views, downloads } = reStructureGraphData(graphData);

  console.log("graphData ->>", graphData)

  console.log("new Date(date).getTime()), =>", new Date("2024-08-21"))


  const options: any = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
        tools: {
          zoom: false,
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "5%",
      }
    },
    xaxis: {
      type: "datetime",
      opposite: true,
      categories: dates.map((date: string) => new Date(date).getTime()), // Convert dates to timestamps
      labels: {
        formatter: (value: number) => moment(value).format("MMM DD, yyyy"),
        format: "MMM dd, yyyy",
        rotate: -45, // Rotate labels to -45 degrees
        maxWidth: 80,
      },
      tickAmount: dates.length > 6 ? 6 : dates.length, // Control the number of ticks based on the number of data points
    },
    yaxis: {
      show: true,
      opposite: true,
      labels: {
        formatter: function (value: any) {
          return parseInt(value, 10); // Convert values to integers
        },
      },
    },
    legend: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
    colors: ["#0A7BAC", "#F89755"],
  };

  const series = [
    {
      name: "Views",
      data: views,
    },
    {
      name: "Downloads",
      data: downloads,
    },
  ];

  return (
    <div id="chart" className="w-full" >
      {graphData?.length > 0 && (
        <ReactApexCharts
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      )}
    </div >
  );
};

// Re- Structure Graph Data Method
const reStructureGraphData = (data: any) => {
  const extractedData = data?.map((item: any) => ({
    date: item.date,
    view: item.views || 0,
    download: item.downloads || item.downdoads || 0,
  }));

  const dates = extractedData?.map((item: any) => item.date);
  const views = extractedData?.map((item: any) => item.view);
  const downloads = extractedData?.map((item: any) => item.download);

  return { dates, views, downloads };
};
