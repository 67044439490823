import * as actionTypes from "./action-types";
import initialState from "./initialState";

export const runReportDataReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case actionTypes.RUN_REPORT_DATA:
      return {
        ...state,
        versionId: action.payload,
      };
      case "RUN_PROCESSED_REPORT":
        return {
          ...state,
          processedReports: action.payload
        }
    default:
      return state;
  }
};
