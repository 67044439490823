import React from "react";
// Components
import { Date } from "./Components";
// Store Utils
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useDispatch } from "react-redux";
import { reportsAnalyticsResetFiltersAction } from "store/filters/reducer.actions";
import {
  filtersInitialState,
  reportsAnalyticsDirectoryFilterState,
} from "store/filters/initialState";

const AppliedFilters = () => {
  // Getting reportsFilter state from Redux store
  const { reportsAnalyticsFilter } = useSelector<
    RootState,
    filtersInitialState
  >((state) => state.Filters);
  const dispatch = useDispatch();

  // Function to check if any filter is applied
  const isAnyFilterApplied = (
    reportsAnalyticsFilter: reportsAnalyticsDirectoryFilterState
  ): boolean => {
    const { date, versionIds, memberIds } = reportsAnalyticsFilter;
    const applied = date?.applied || versionIds?.applied || memberIds?.applied;
    return applied;
  };

  const clearAllFilters = () => {
    dispatch(reportsAnalyticsResetFiltersAction());
  };
  return (
    <div className="flex flex-wrap items-start justify-start flex-grow-0 flex-shrink-0 gap-1">
      {isAnyFilterApplied(reportsAnalyticsFilter) && (
        <button
          onClick={clearAllFilters}
          type="button"
          className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 border-none outline-none focus:outline-none rounded-3xl"
        >
          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative pt-1.5 pb-2">
            <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary">
              Clear All Filters
            </p>
          </div>
        </button>
      )}
      <div
        style={{ maxWidth: "90%" }}
        className="flex flex-wrap items-center justify-start"
      >
        <div className="flex flex-wrap gap-2">
          <Date
            name="date"
            from={reportsAnalyticsFilter?.date?.from}
            to={reportsAnalyticsFilter?.date?.to}
            isApplied={reportsAnalyticsFilter?.date?.applied}
          />
        </div>
      </div>
    </div>
  );
};

export default AppliedFilters;
