import * as actionTypes from "./action-types";

export const runReportDataAction = (versionId: any) => {
  return {
    type: actionTypes.RUN_REPORT_DATA,
    payload: versionId,
  };
};

export const runProcessedReports = (data: any) => {
  return {
    type: "RUN_PROCESSED_REPORT",
    payload: data,
  };
};
